import React, { useState, useEffect, useContext } from 'react';
import styled from '@lib/styled';
import Button from '@components/atoms/Button';
import useUser from '@hooks/useUser';
import {
  Breadcrumb,
  Card,
  Typography,
  notification,
  Divider,
  Space,
  Tabs,
  message,
  Empty,
  Affix,
  Modal,
  Row,
  Col
} from 'antd';
import Footer from '@atoms/Footer';
import {
  HomeOutlined,
  PlusCircleOutlined,
  PhoneOutlined,
  CloseOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { primaryColor, dangerColor, warningColor } from '@lib/theme';
import { useRouter } from 'next/router';
import useAnnouncements from '@hooks/useAnnouncements';
import Content from '@containers/Announcements/Content';

const { Text, Title } = Typography;
const { TabPane } = Tabs;

export default function Announcements() {
  const router = useRouter();
  const { announcements, loading, activeAnnouncement } = useAnnouncements();
  return (
    <>
      {announcements.map(announcement => (
        <Announcement
          key={announcement.id}
          announcement={announcement}
          active={activeAnnouncement?.id === announcement.id}
        />
      ))}
    </>
  );
}

const Announcement = ({ announcement, active }: any) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(true);
  const title = announcement?.title;
  const body = announcement?.body;
  const preview = announcement?.preview;
  const supplement = announcement?.supplement;
  const color = warningColor(1);
  const close = () => {
    setShow(false);
    // setTimeout(() => setOpen(false), 100)
  };
  return (
    <>
      <Affix offsetTop={0}>
        <Container open={show && !!announcement && active} color={color}>
          <Caption onClick={() => setOpen(true)}>
            <ExclamationCircleOutlined size={15} style={{ color }} />
            <Text style={{ color: 'white', marginRight: '20px' }}>
              <span style={{ fontWeight: 'bold' }}>{title}:</span> {preview}
            </Text>
            <Button
              size="small"
              onClick={() => setOpen(true)}
              style={{ color: 'white', fontColor: 'white' }}
              type="ghost">
              <span style={{ color: 'white' }}>Open</span>
            </Button>
          </Caption>
          <Actions>
            <a onClick={close}>
              <Button
                size="large"
                onClick={close}
                icon={<CloseOutlined style={{ color: 'white' }} />}
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontColor: 'white'
                }}
                type="link"></Button>
            </a>
          </Actions>
        </Container>
      </Affix>
      <Modal
        width="80vw"
        title={`${title}: ${preview}`}
        visible={open}
        footer={null}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}>
        <ModalContent>
          {body}
          <Content id={supplement} embedded={true} />
          <br />
          <br />
          <Button block onClick={() => setOpen(false)} type="primary">
            OK
          </Button>
        </ModalContent>
      </Modal>
    </>
  );
};
const Container = styled.div`
  display: ${({ open }: any) => (open ? 'flex' : 'none')};
  background: ${({ color }: any) => color || primaryColor(1)};
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  min-height: 3rem;
  z-index: 1000;
  -webkit-box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.47);
  box-shadow: 0px 10px 29px -14px rgba(0, 0, 0, 0.47);
`;

const Caption = styled.a`
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex: 1;
  padding: 0px 1rem;
`;

const Actions = styled.div`
  justify-content: flex-end;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 0px 1rem;
  flex: 0;
`;

const ModalContent = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
`;
