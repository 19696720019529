import firebase, { firestore } from '@lib/firebase';
import { Modal, notification } from 'antd';
import { useDocument, useCollection } from 'react-firebase-hooks/firestore';
import _ from 'lodash';
import moment from 'moment';
import { dangerColor, primaryColor } from '@lib/theme';
import { useRouter } from 'next/router';

const { confirm } = Modal;
import {
  ExclamationCircleOutlined,
  CopyOutlined,
  FieldTimeOutlined,
  DeleteOutlined
} from '@ant-design/icons';

export default function useAnnouncements() {
  const uid = firebase.auth()?.currentUser?.uid || '';
  const router = useRouter();
  const [snapshot, loading, error] = useCollection(
    firebase.firestore().collection('announcement')
  );
  const announcements =
    snapshot?.docs.map(item => ({ ...item.data(), id: item.id })) || [];
  const activeAnnouncement =
    announcements && announcements.length > 0 ? announcements[0] : null;
  return {
    announcements,
    activeAnnouncement,
    loading,
    error
  };
}
