import React from 'react';
import dynamic from 'next/dynamic';

export default function Content({ id, ...props }) {
  if (!id) {
    return null;
  }
  const Content_ = dynamic(() => import(`./${id}.mdx`), { ssr: false });
  if (!Content_) {
    return null;
  }
  return (
    <>
      <Content_ {...props} />
    </>
  );
}
